.HeadCell {
  display: flex;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  &.HeadCellSortable {
    cursor: pointer;
    text-decoration: underline;
  }
}
