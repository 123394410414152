.Container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 580px;
  width: 1120px;
}

.ContainerInner {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  border: 1px solid var(--mui-palette-divider);
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.ContainerWrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.LoginPageBackground {
  height: 100%;
  flex: 1;
  position: relative;
}

.LoginPageMain {
  width: 450px;
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}
.LoginPageMainFullWidth {
  width: 100%;
}

.LoginPage {
  padding: 0;
}
