.ResultRowValue {
  text-align: right;

  /* Vendor-prefixed styles for cross-browser support */
  text-align: -webkit-right;
  text-align: -moz-right;
  text-align: -ms-right;
  text-align: -o-right;

  /* Required for older IE versions */
  unicode-bidi: embed;
}
