.Badge {
    font-weight: normal;
    font-size: 12px;
}

/* 
    mui is not working for some reason - the css classes are in the wrong order
    temp fix is by using more specific selectors
*/
div.statusClosed {
    background-color: var(--mui-palette-success-badge);
}

div.statusSaved {
}

div.statusCancelled,
div.statusVoided {
    background-color: var(--mui-palette-destructive-badge);
    color: var(--mui-palette-destructive-text);
}

div.stateActive {
    background-color: var(--mui-palette-success-badge);
    color: var(--mui-palette-primary-text);
}
