body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiTableBody-root > .MuiTableRow-root:last-child > .MuiTableCell-root {
  border-bottom: none !important;
}

#root,
html,
body {
  height: 100%;
}

#root .react-tabs {
  margin-top: 10px;
}

#root .react-tabs__tab-list {
  border-bottom: 0;
  margin: 10px;
  margin-top: 10px;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid var(--mui-palette-TableCell-border);
}

#root .react-tabs__tab {
  border: 0;
  border-radius: 5px;
  font-size: 13px;
  margin-bottom: 10px;
}

#root .react-tabs__tab--selected {
  background-color: #ffefe5;
  color: #f37121;
  font-weight: bold;
}
