.DropZone {
    background-color: "#fff";
    padding: 16px;
    border-radius: 8px;
    border: 2px dashed rgba(0, 0, 0, 0.3);
    min-height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        border-color: var(--mui-palette-primary-main);
        cursor: pointer;
    }
}

.Thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}
