.AnalyticTile {
    min-width: 300px;
    width: 100%;
    flex: 1;
    height: 200px;
    border-radius: 15px;
    padding: 20px;
    background-color: #cbcbcb;
    border-radius: 5px;
}
