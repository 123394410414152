.Avatar {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background-color: #f68d4d;
    justify-content: center;
    align-items: center;
    display: flex;
}

.Initials {
    font-size: 16px;
    color: #fff;
}
