.Wrapper {
    width: 100%;
}

.Radio {
    display: none;
    visibility: hidden;
}

.Radio + div {
    padding: 10px;
    cursor: pointer;
    display: block;
    width: 100%;
    border-radius: 4px;
    background-color: #f3f3f3;
    border: 1px solid var(--mui-palette-divider);
}

.Radio:checked + div {
    background-color: #ffe7d9;
    border: 1px solid #d37134;
}
