.Wrapper {
    height: 40px;
    width: 40px;
    position: relative;
    border: 1px solid var(--mui-palette-border-standard);

    &.variantUpload {
        border: 2px dashed var(--mui-palette-border-standard);
    }
}

.Clickable {
    cursor: pointer;

    &:hover {
        &.Wrapper {
            border-color: var(--mui-palette-primary-main);
        }
        .PlaceholderWrapper svg {
            color: var(--mui-palette-primary-main);
        }
    }
}

.PlaceholderWrapper {
    height: 40px;
    width: 40px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PlaceholderWrapper svg {
    color: var(--mui-palette-border-standard);
}

.QuantityWrapper {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--mui-palette-primary-main);
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -10px;
    right: -10px;
}

.sizeSmall {
    width: 40px;
    height: 40px;

    .PlaceholderWrapper {
        width: 40px;
        height: 40px;
    }
}

.ProductImageThumbnail {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.sizeLarge {
    width: 90px;
    height: 90px;

    .PlaceholderWrapper {
        width: 90px;
        height: 90px;
    }

    svg {
        font-size: 40px;
    }
}
