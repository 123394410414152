.InputWrapper {
    width: 370px;
    overflow: hidden;
}

.Input {
    width: 2rem !important;
    height: 3rem;
    margin-right: 1rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}
