.Wrapper {
    width: 100%;
}

.Radio {
    display: none;
    visibility: hidden;
}

.Radio + div {
    padding: 10px;
    cursor: pointer;
    display: block;
    display: flex;
    flex: 1;
    border-radius: 4px;
    background-color: rgb(218, 219, 221);
}

.Radio:checked + div {
    background-color: #f37121;
}

.Radio:checked + div > p {
    color: #fff;
}
